
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";
import layout from "./components/layout.vue";
import stopPage from "./views/stopPage.vue";
import regionJune from "./views/regionJune.vue";
import { moveDom } from "@/directives/moveDom";

export default defineComponent({
  components: {
    layout,
    stopPage,
    regionJune,
  },
  setup() {
    const router = useRouter();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      move: {} as HTMLElement,
      msg: "数据加载中...",
      isLoading: true,
      isShowIcon: false,
      page_type: "",
      pk_type: "",
      dataList: [] as {
        name: string;
        page_type: string;
        pk_type: string;
      }[],
    });

    const getPkTypeList = () => {
      store
        .dispatch("actPkTypeList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.page_type = res.data_list.page_type;
            dataMap.dataList = res.data_list.pk_list;
            if (dataMap.dataList.length === 0) {
              dataMap.msg = "暂无数据";
            }
            if (dataMap.page_type !== "arr") {
              dataMap.pk_type = res.data_list.pk_list[0].pk_type;
            }
            dataMap.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleClick = (item: {
      name: string;
      page_type: string;
      pk_type: string;
    }) => {
      dataMap.page_type = item.page_type;
      dataMap.pk_type = item.pk_type;
      dataMap.isShowIcon = true;
    };

    const back = () => {
      dataMap.page_type = "arr";
      dataMap.isShowIcon = false;
      dataMap.move.style.left = "unset";
      dataMap.move.style.top = "80%";
      dataMap.move.style.right = "20px";
    };

    getPkTypeList();
    return {
      ...toRefs(dataMap),
      handleClick,
      back,
    };
  },
  directives: {
    move: moveDom,
  },
});
