<template>
  <div class="regionJune">
    <Layout
      src="/static/images/juneTop.png"
      :isLoading="isLoading"
      :msg="msg"
      bgColor="#d30d30"
    >
      <div class="p-h-10">
        <div class="node" v-for="(item, index) in data_list" :key="index">
          <EchartsTable
            :datas="item"
            width="100%"
            :height="getHeight(item.yAxis.data.length)"
            :event="events"
            :id="'echarts:' + index"
            @click="getIndex(index)"
          >
          </EchartsTable>
        </div>
      </div>
      <div class="pic"><img src="/static/images/juneBottom.png" alt="" /></div>
    </Layout>
  </div>
</template>

<script lang="ts">
import { ref, reactive, defineComponent, toRefs } from "vue";
import { useRouter } from "vue-router";
import EchartsTable from "@/components/chart/echartsTable.vue";
import Layout from "@/pages/wechatNirvana/components/layout.vue";
import * as echarts from "echarts";
import { apiGetPkResult } from "@/api/modules/app";

export default defineComponent({
  props: {
    pk_type: {
      type: String,
      defualt: "",
    },
  },
  components: {
    EchartsTable,
    Layout,
  },
  setup(props) {
    const router = useRouter();
    const currIndex = ref(0);

    const fn = (obj: any, el: any) => {
      if (obj.componentSubType == "bar") {
        const idx = parseInt(el._dom.parentElement.id.split(":")[1]);
        const name = obj.name;
        const seriesName = obj.seriesName;
        const group_key = dataMap.originData[idx]["group_key"];
        const stage = dataMap.originData[idx]["stage_list"].indexOf(seriesName);
        const shr_number = dataMap.originData[idx]["number_list"][name];
        router.push({
          name: "pkCompare",
          query: {
            pk_type: props.pk_type,
            group_key,
            shr_number,
            stage,
          },
        });
      }
    };
    const dataMap = reactive({
      msg: "数据加载中...",
      isLoading: true,
      originData: [] as GetNirvanaListNewNode[],
      data_list: [] as any[],
      events: {
        eventName: "click",
        callback: fn,
      },
    });

    const getNirvanaNew = () => {
      apiGetPkResult({
        pk_type: props.pk_type!,
      })
        .then((res: any) => {
          if (res.status == 200) {
            dataMap.isLoading = false;
            dataMap.originData = JSON.parse(JSON.stringify(res.data_list));
            let t = {
              title: {
                left: "center",
                top: "10px",
                textStyle: {
                  display: "inline-block",
                  width: "100%",
                },
                backgroundColor: "#fff5d9",
                padding: [5, 50, 5, 50],
              },
              tooltip: {
                // trigger: 'axis',
                // axisPointer: {
                //   type: 'shadow'
                // },
                show: false,
              },
              legend: {
                orient: "horizontal",
                bottom: 10,
                left: "center",
                // icon: 'circle',
                textStyle: {
                  color: "#8995CB",
                },
              },
              grid: {
                top: "50px",
                left: "5%",
                right: "5%",
                bottom: "35px",
                containLabel: true,
              },
              xAxis: {
                type: "value",
                boundaryGap: [0, 0.01],
                axisLine: {
                  show: true,
                },
              },
              yAxis: {
                type: "category",
                axisLabel: {
                  rotate: 45,
                },
                triggerEvent: true,
              },
              series: [
                {
                  name: "",
                  data: [],
                  type: "bar",
                  label: {
                    show: true,
                    position: "insideLeft",
                    color: "#fbd394",
                    rich: {
                      a: {
                        fontSize: 10,
                      },
                      c: {
                        fontWeight: 700,
                        fontSize: 14,
                      },
                    },
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "#f05556",
                      },
                      {
                        offset: 1,
                        color: "#e41e26",
                      },
                    ]),
                  },
                },
                {
                  name: "",
                  data: [],
                  type: "bar",
                  label: {
                    show: true,
                    position: "insideLeft",
                    color: "#fbd394",
                    rich: {
                      a: {
                        fontSize: 10,
                      },
                      c: {
                        fontWeight: 700,
                        fontSize: 14,
                      },
                    },
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "#f05556",
                      },
                      {
                        offset: 1,
                        color: "#e41e26",
                      },
                    ]),
                  },
                },
              ],
            };
            res.data_list.forEach((el: any, index: any) => {
              let _ = JSON.parse(JSON.stringify(t));
              _.title["text"] = el.title;
              _.yAxis["data"] = el.name;
              el.stage_list.forEach((stage: any, idx: any) => {
                _.series[idx]["name"] = stage;
                _.series[idx].label["formatter"] = (params: any) => {
                  return `{c|${params.data}} {a|(${params.seriesName})}`;
                };
                _.series[idx]["data"] = res.data_list[index][stage];
              });
              dataMap.data_list.push(_);
            });
          }
        })
        .catch((err) => {
          console.log("err");
        });
    };

    getNirvanaNew();

    const getHeight = (num: number) => {
      if (num <= 2) {
        return "300";
      } else {
        return (num - 2) * 60 + 300 + "";
      }
    };

    const getIndex = (index: number) => {
      currIndex.value = index;
    };

    return {
      ...toRefs(dataMap),
      getHeight,
      getIndex,
    };
  },
});
</script>

<style lang="less" scoped>
.regionJune {
  .node {
    display: block;
    background: #fff;

    &:first-child {
      margin: 0 0 20px;
    }

    &:not(:first-child) {
      margin: 20px 0;
    }
  }

  .pic {
    font-size: 0;
  }
}
</style>
